<div class="confirm-container">
  <!-- dialog header -->
  <div class="header">
    <h2 class="label-medium" *ngIf="data.title">{{ data.title }}</h2>
    <!-- close icon -->
    <button class="close-btn-icon" (click)="_dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-icon *ngIf="data.icon" class="dialog-icon">{{ data.icon }}</mat-icon>
  <!-- dialog content -->
  <h3 class="label-small-2 content">
    {{ data.content }}
  </h3>
  <!-- dialog actions -->
  <div class="action-btns">
    <input *ngIf="data.action.accept == 'send-attachment'" (change)="uploadFiles($event,attachments, 2097152, 3)"
      multiple class="warning-border" style="display: none" name="image" required #uploadFilesInput type="file"
      accept="application/pdf,image/png, image/jpg, image/jpeg" />
    <button mat-btn class="btn yes label-medium-2"
      [ngStyle]="{ '--color': data.action.color ? data.action.color : '#07b27e' }"
      (click)="data.action.accept == 'send-attachment' ? triggerUploadFiles() : accept(true)">
      {{ "confirm-dialog." + data.action.accept | translate }}
    </button>
    <button mat-btn class="btn ignore label-medium-2" (click)="accept(false)">
      {{ "confirm-dialog." + data.action.refuse | translate }}
    </button>
  </div>
</div>