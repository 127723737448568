// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  api_url: "https://lawhatyapidev.kortobaa.net/",
  production: true,
  name: "dev",
  firebase: {
    apiKey: "AIzaSyCO_LE9tfpgragOjSceLuISFI4VjDCwaQ0",
    authDomain: "lawhaty-dev.firebaseapp.com",
    projectId: "lawhaty-dev",
    storageBucket: "lawhaty-dev.appspot.com",
    messagingSenderId: "967179590896",
    appId: "1:967179590896:web:e826a434f152ae0b34b635",
    vapidKey: "BMb-eVzxQPa7O5_wipeubrwTQRsR87SATo1Lj_MXQ9nqHcf-bG13cq3qBt7u-nCm9S49InBU1KFT6SSlSdxcboc",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
