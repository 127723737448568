<div class="reload-page" *ngIf="errorMessage">
  <div class="reload-page__content">
    <figure>
      <img [src]="
          base64String
        " alt="error image" />
    </figure>
    <div class="title-medium-3">{{errorMessage.title}}</div>
    <p class="label-medium-2" [innerHTML]="formattedText"></p>
    <a class="btn btn-secondary label-large" (click)="reloadPage()"> إعادة تحميل الصفحة </a>
  </div>
</div>