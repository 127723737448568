<div class="notification" id="openNotify" #openNotify>
  <div class="notify-icon" (click)="notification.emitCloseDialog('close')" id="notify">
    <mat-icon matSuffix class="material-icons-outlined" id="notify"> notifications </mat-icon>
  </div>
  <div class="notification__wrapper">
    <!-- close icon on responsive -->
    <mat-icon (click)="notification.emitCloseDialog('close')" class="close-icon">
      close
    </mat-icon>
    <ng-container *ngIf="!loading && notifications.length > 0; else noNotify">

      <div class="notification__wrapper__header">

        <mat-slide-toggle [(ngModel)]="!toggleOn" (click)="toggle(!toggleOn)"> </mat-slide-toggle>
        <p>عرض الغير مقروءة فقط</p>
      </div>

      <div class="notification__wrapper__content">
        <div class="notification__wrapper__content_wrapper">
          <div class="notification__wrapper__content_wrapper__items">
            <div class="w-100" *ngFor="let notification of notifications; let i = index"
              [ngClass]="{ seen: notification.seen }">
              <p class="date"
                *ngIf="i >= 0 && (notifications[i].createdAt | date) != (notifications[i - 1]?.createdAt | date)">
                {{ notification.createdAt | datePipe: "dddd" }}
              </p>
              <div *ngIf="notification" [ngStyle]="{
                              cursor: notification?.notificationType ? 'pointer' : 'default'
                            }" class="notification__wrapper__content_wrapper__items-item" (click)="
                              notification?.notificationType
                                ? goTo(notification.notificationType, notification.operationId, notification.id)
                                : close()
                            " [ngClass]="{ seen: !notification.seen }">
                <p class="title body-large">{{notification.title}}</p>
                <p class="message body-medium-2">{{ notification.message }}</p>
                <div *ngIf="!notification.seen">
                  <i></i>
                </div>
                <mat-divider *ngIf="notifications.length - (i + 2) >= 0"></mat-divider>
              </div>
            </div>

          </div>
          <div class="more_container" *ngIf="hasMoreNotifications">
            <div class="more" (click)="loadMore()">
              المزيد ...
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noNotify>
      <div class="no-notify" *ngIf="!loading && notifications.length == 0">لا يوجد اشعارات بعد ...</div>
    </ng-template>
    <div class="spinner" *ngIf="loading">
      <app-spinner [customLoader]="true" [bgColor]="secondaryColor"></app-spinner>
    </div>
  </div>
</div>