<nav class="side-menu">
  <div class="side-menu__wrapper" [ngClass]="{
      close: sideNavToggleBtn
    }">
    <!-- close icon on responsive -->
    <mat-icon (click)="sideNavToggleBtnRes.emit(false)" class="close-icon"> close </mat-icon>
    <!-- logo -->
    <a routerLink="" class="logo">
      <img src="../../../../assets/images/logo.svg" alt="logo" />
    </a>
    <ul class="nav-list">
      <!-- menu navigation -->
      <ng-container *ngFor="let item of sideMenu">
        <li class="nav-item" *ngIf="item.condition" [ngClass]="{
            active: route && route.includes(item.title),
            open: item.hasChildren && openFlag && item.title == routeTitle
          }" routerLinkActive="active" (click)="!item.hasChildren ? goTo(item.route) : ''"
          (click)="toggle(true, openFlag, item.title)">
          <a>
            <span class="nav-icon">
              <img src="{{ item.image + (route && route.includes(item.title) ? '-white.svg' : '.svg') }}" />
            </span>
            <span class="nav-title">
              {{ "breadCrumb." + item.title | translate }}
            </span>
          </a>
          <!-- icon for menu navigation if have children route -->
          <mat-icon class="expand-icon material-icons-round" *ngIf="item.hasChildren">
            {{ openFlag && item.title == routeTitle ? "expand_less" : "expand_more" }}
          </mat-icon>
        </li>

        <!-- dropdown for menu navigation if have children route -->
        <ul class="dropdown" [ngClass]="{
            'dropdown-menu': sideNavToggleBtn && subMenu && item.hasChildren && item.title == routeTitle,
            dropdownLocation: item?.id == 'pages',
            'mobile-num-ads': item?.id == 'mobile-num-ads',
            'social-account-ads': item?.id == 'social-account-ads',
            'domain-ads': item?.id == 'domain-ads'

          }" [ngStyle]="{
            display: item.hasChildren && item.title == routeTitle && openFlag && !sideNavToggleBtn ? 'block' : 'none'
          }">

          <ng-container *ngIf="item.hasChildren">
            <li *ngFor="let child of item.children" (click)="goTo(child.route)">
              <a [ngClass]="{ active: route && route.includes(item.title) && route.includes(child.title) }">
                {{ "breadCrumb." + child.title | translate }}
              </a>
            </li>
          </ng-container>
        </ul>
      </ng-container>
      <!-- logout -->
      <li class="nav-item logout" (click)="logout()">
        <a>
          <span class="nav-icon">
            <img src="../../../../assets/images/side-menu/logout.svg" alt="تسجيل الخروج" />
          </span>
          <span class="nav-title">
            {{ "breadCrumb." + "logout" | translate }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</nav>